
import Vue from "vue";

export default Vue.extend({
  props: ["default"],

  data() {
    return {
      icon: this.default,
      icons: [
        "mdi-information",
        "mdi-information-outline",
        "mdi-help-circle-outline",
        "mdi-handshake",
        "mdi-forum",
        "mdi-message-text",
        "mdi-note-multiple",
        "mdi-account-multiple",
        "mdi-heart",
        "mdi-head-alert-outline",
        "mdi-head-lightbulb",
        "mdi-head-cog",
        "mdi-head-heart",
        "mdi-hand-coin",
        "mdi-hand-heart-outline",
        "mdi-chart-bar",
        "mdi-chart-areaspline",
        "mdi-chart-pie",
        "mdi-finance",
        "mdi-home-analytics",
        "mdi-home-city-outline",
        "mdi-home-lightbulb",
        "mdi-thought-bubble",
        "mdi-cloud",
        "mdi-database",
        "mdi-earth",
        "mdi-cellphone-text",
        "mdi-link-variant",
        "mdi-map-marker",
      ],
    };
  },

  watch: {
    default() {
      this.icon = this.default;
    },
  },

  methods: {
    onSelectIcon($event: any) {
      this.$emit("onSelectIcon", $event);
    },
  },
});
