
import Vue from "vue";
import AdminUserGlobalState from "@/store/modules/AdminUserGlobalState";
import ImageSelect from "@/components/base/ImageSelect.vue";
import { getModule } from "vuex-module-decorators";

export default Vue.extend({
  name: "SelectLogos",

  components: {
    ImageSelect,
  },

  data() {
    return {
      dialog: false,
      imagesDialog: false,
      imagesPaths: [],
      images: [],
      selectedLogo: "",

      selectedImage: "",
      selectedImageLeft: "",
      selectedImageRight: "",
      initialSelectedImageLeft: "",
      initialSelectedImageRight: "",
      logoSide: "",
      imageSelectKey: 0,
      adminUserGlobalState: getModule(AdminUserGlobalState, this.$store),
    };
  },

  computed: {
    isAdminLayout(): boolean {
      if (
        Vue.$keycloak.hasResourceRole("admin") &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        return this.adminUserGlobalState.isAdminUserActive;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.selectedImageLeft = this.$store.getters.leftLogoUrl;
    this.selectedImageRight = this.$store.getters.rightLogoUrl;
    if (this.selectedImageLeft === null) {
      this.selectedImageLeft = "";
    }
    if (this.selectedImageRight === null) {
      this.selectedImageRight = "";
    }
    this.initialSelectedImageRight = this.selectedImageRight;
    this.initialSelectedImageLeft = this.selectedImageLeft;
  },

  created() {
    const imagePaths: any = require.context(
      "@/assets/logos",
      false,
      /^.*\.png$/
    );
    this.imagesPaths = imagePaths;
  },

  methods: {
    close() {
      this.dialog = false;
      this.selectedImageRight = this.$store.getters.rightLogoUrl;
      this.selectedImageLeft = this.$store.getters.leftLogoUrl;
      this.initialSelectedImageRight = this.$store.getters.rightLogoUrl;
      this.initialSelectedImageLeft = this.$store.getters.leftLogoUrl;
    },

    closeImagesDialog() {
      this.imagesDialog = false;
      this.imageSelectKey++;
      this.selectedImageRight = this.initialSelectedImageRight;
      this.selectedImageLeft = this.initialSelectedImageLeft;
    },

    selectLogo(side: string) {
      this.logoSide = side;
      this.selectedImage =
        side === "right" ? this.selectedImageRight : this.selectedImageLeft;
      this.imagesDialog = true;
    },

    onSelectLogo(logo: any) {
      this.selectedImageRight =
        this.logoSide === "right" ? logo : this.selectedImageRight;
      this.selectedImageLeft =
        this.logoSide === "left" ? logo : this.selectedImageLeft;
    },

    onSelectImage() {
      this.initialSelectedImageRight = this.selectedImageRight;
      this.initialSelectedImageLeft = this.selectedImageLeft;
      this.imagesDialog = false;
    },

    onSaveLogo() {
      this.$store.commit("setLeftLogo", this.selectedImageLeft);
      this.$store.commit("setRightLogo", this.selectedImageRight);

      this.$emit(
        "onSaveLogos",
        this.$store.getters.leftLogoUrl,
        this.$store.getters.rightLogoUrl
      );

      this.dialog = false;
    },
  },
});
