
import Vue from "vue";
import Styles from "@/assets/styles/ColorPalette.scss";

export default Vue.extend({
  props: ["label", "default"],

  data() {
    let colorPalettes = Object.values(Styles);
    const numberOfAvailableColors = colorPalettes.length;
    const numberOfGroupedColors = 2;

    let colorPaletteArray = [];
    for (let i = 0; i < numberOfAvailableColors; i += numberOfGroupedColors) {
      i + 1 < numberOfAvailableColors
        ? colorPaletteArray.push([colorPalettes[i], colorPalettes[i + 1]])
        : colorPaletteArray.push([colorPalettes[i]]);
    }

    return {
      menu: false,
      color: this.default,
      swatches: colorPaletteArray,
    };
  },

  watch: {
    default() {
      this.color = this.default;
    },
  },

  methods: {
    onSelectColor(event: any) {
      this.$emit("onSelectColor", event.hex);
    },
  },

  computed: {
    swatchStyle(): any {
      return {
        backgroundColor: this.color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
});
