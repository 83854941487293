
import Vue from "vue";

export default Vue.extend({
  name: "ImageSelect",

  props: ["default", "imagePaths", "logos"],

  data() {
    return {
      images: [""],
      selectedImage: -1,
    };
  },

  watch: {
    default() {
      this.selectedImage = this.getSelectedImage();
    },
  },

  created() {
    this.images = [];
    this.getImageUrls();
    this.selectedImage = this.getSelectedImage();
  },

  methods: {
    getImageUrls(): any {
      this.images = this.imagePaths
        .keys()
        .map(this.imagePaths)
        .map((val: string) => {
          return `${val}`;
        });

      if (this.logos === true) {
        this.images.push("");
      }
    },

    getSelectedImage(): number {
      return this.images.findIndex((elem: string) => elem == this.default);
    },

    select(idx: number, img: string) {
      this.selectedImage = idx;
      this.$emit("onSelectImage", img);
    },
  },
});
